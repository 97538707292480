<template>
  <section class="event-evolution-list">
    <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="事件名称">
          <el-input v-model="form.name" size="small" placeholder="请输入事件名称"></el-input>
        </el-form-item>
         <el-form-item label="创建时间">
          <el-date-picker
            v-model="form.createDate"
            type="date"
            size="small"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="selectEventByCondition" :disabled="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="content">
      <el-button type="primary" size="mini" @click="handleAdd" :disabled="loading">添加</el-button>
      <div class="info-list" v-loading="loading">
        <div style="width: 100%;text-align: center;color: #898989;margin-top: 80px;" v-if="list.length === 0">暂无数据</div>
        <div class="info-item" v-for="item in list" :key="item.id" @click="showDetails(item.id)">
          <div class="top-wrap">
            <span class="title">{{ item.name }}</span>
            <div class="controller">
              <!-- <el-button type="text" @click.prevent.stop.once="handleOption(item.id,item.name)" :disabled="item.status===1?true:false"><i class="el-icon-warning-outline"></i></el-button> -->
              <el-button type="text" @click.prevent.stop.once="handleEdit(item.id)" :disabled="item.status===1?true:false"><i class="el-icon-edit-outline"></i></el-button>
              <el-button type="text" @click.prevent.stop.once="handleRemove(item.id)"><i class="el-icon-delete"></i></el-button>
            </div>
          </div>
          <div class="info">
            <span class="createTime">所属项目：{{ item.projectName }}</span>
            <span class="createTime">创建日期：{{ dateFormat(item.createDate) }}</span>
            <div class="start-end-time">起止时间：<span class="start-time">{{ dateFormat(item.startDate) }}</span> 至 <span class="end-time">{{ dateFormat(item.endDate) }}</span></div>
          </div>
          <div class="isEnd" v-show="item.status">已结束</div>
        </div>
      </div>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[ 10, 20, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="sizeChange"
          @current-change="currentChange"
          :total="count">
        </el-pagination>
      </section>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        top="15%"
        :before-close="handleClose">
        <span class="prompt"><i class="el-icon-warning"></i>此操作将永久删除该内容，不可恢复，是否继续？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmDelete">确 定</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { initEventEvolution, delEventEvolution } from '@/api/eventEvolution'
export default {
  data () {
    return ({
      form: {
        name: '',
        createDate: ''
      },
      formData: {
        eventName: '',
        eventCreateDate: ''
      },
      deleteId: '',
      list: [],
      count: 0,
      limit: 10,
      currentPage: 1,
      loading: false,
      dialogVisible: false
    })
  },
  methods: {
    dateFormat (date) {
	    date = date.replace(/-/g,"/").substr(0,date.indexOf('.'))
      var t = new Date(date)
      var year=t.getFullYear(),
     　　 month=t.getMonth()+1,
     　　 day=t.getDate(),
    　　  hour=t.getHours(),
    　　  min=t.getMinutes(),
    　　  sec=t.getSeconds()
　　  var newTime = year + '-' +
    　　  (month < 10 ? '0'+ month : month) + '-' +
   　　   (day <10 ? '0' + day : day) + ' ' +
    　　  (hour <10 ? '0' + hour : hour) + ':' +
    　　  (min <10 ? '0' + min : min)
　　  return newTime
    },
    async selectEventByCondition () {
      this.loading = true
      this.formData.eventName = this.form.name
      this.formData.eventCreateDate = this.form.createDate
      this.currentPage = 1
      try {
        const res = await initEventEvolution({ pageSize: this.limit,
          current: this.currentPage,
          del: 0,
          name: this.form.name,
          createDate: this.form.createDate })
        this.list = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async confirmDelete () {
      try {
        const res = await delEventEvolution({ id: this.deleteId })
        this.currentPage = 1
        if (res.code === 0) {
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        this.$message.error('删除失败')
      }
      const res2 = await initEventEvolution({ pageSize: this.limit, current: this.currentPage, del: 0 })
      this.list = res2.data
      this.count = res2.count
      this.dialogVisible = false
    },
    handleRemove (id) {
      this.deleteId = id
      this.dialogVisible = true
    },
    handleEdit (id) {
      console.log(id)
      this.$router.push(`/eventEvolution/modifyEvent/${id}`)
    },
    handleOption (id, name) {
      console.log(id)
      this.$router.push(`/eventEvolution/option/${id}/${name}`)
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleAdd () {
      this.$router.push('/eventEvolution/addEvent')
    },
    showDetails (id) {
      console.log(id)
      this.$router.push(`/eventEvolution/evolutionDetails/${id}`)
    },
    async sizeChange (i) {
      this.loading = true
      this.currentPage = 1
      this.limit = i
      try {
        const res = await initEventEvolution({ pageSize: this.limit, current: this.currentPage, del: 0, name: this.formData.eventName, createDate: this.formData.eventCreateDate })
        this.list = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async currentChange (i) {
      this.loading = true
      this.currentPage = i
      try {
        const res = await initEventEvolution({ pageSize: this.limit, current: this.currentPage, del: 0, name: this.formData.eventName, createDate: this.formData.eventCreateDate })
        this.list = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async initData () {
      this.loading = true
      try {
        const res = await initEventEvolution({ pageSize: this.limit, current: this.currentPage, del: 0 })
        this.list = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    }
  },
  async mounted () {
    this.initData()
  }
}
</script>

<style lang="scss" scoped="">
.event-evolution-list {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 2px;
    padding-top: 15px;
    padding-left: 20px;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    .el-form {
      .el-form-item {
        margin-right: 30px;
        &:nth-last-child(2) {
          margin-right: 30px;
        }
      }
    }
  }
  .content {
    width: 100%;
    min-height: calc(100% - 90px);
    margin-top: 20px;
    background-color: #fff;
    border-radius: 2px;
    padding: 20px 30px;
    position: relative;
    .info-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 50px;
      .info-item {
        width: 49%;
        margin-right: 1%;
        height: 155px;
        background: #fff;
        border: 1px solid #efefef;
        box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
        border-radius: 2px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        position: relative;
        .top-wrap {
          width: 100%;
          font-size: 18px;
          color: #333333;
          display: flex;
          border-bottom: 1px solid #efefef;
          padding-bottom: 10px;
          justify-content: space-between;
          align-items: center;
          .title {
            cursor: pointer;
          }
          .controller {
            .el-button {
              padding: 0px;
              &.el-button--text {
                color: #ACACAC;
              }
            }
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #ADADAD;
          margin-top: 15px;
          .createTime {
            margin-bottom: 13px;
          }
          /* .start-end-time {
            .start-time {
              margin-right: 20px;
            }
          } */
        }
        .isEnd {
          position: absolute;
          bottom: 25px;
          right: 10px;
          font-size: 25px;
          font-weight: bolder;
          color: rgba(204,0,0,.9);
          transform:rotate(-30deg);
        }
      }
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 30px;
      right: 30px;
      .count {
        line-height: 32px;
      }
      .el-pagination {
        .btn-prev, .btn-next {
          background-color: transparent;
        }
        .el-input {
          background-color: transparent;
        }
        .el-pager {
          li {
            background: none;
          }
        }
      }
    }
    .el-dialog {
      .el-dialog__body {
        padding: 10px 20px;
        .prompt {
          height: 28px;
          display: flex;
          align-items: center;
          .el-icon-warning {
            font-size: 28px;
            color: #FF8B35;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
</style>
